/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~swiper/scss';
@import '~swiper/scss/pagination';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@mixin flex-display($justify: center, $align: center) {
    margin: 0 auto;
    display: flex;
    justify-content: $justify;
    align-items: $align;
}

* {
    font-family: Montserrat, Verdana !important;
}

ion-toolbar.qvery-header {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.65) -34.83%, rgba(255, 255, 255, 0.73) 106.74%);

    & ion-back-button {
        --color: #2E2E2E;
    }

    & ion-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #2E2E2E;
    }
}
.ion-color-main {
    --ion-color-base: var(--ion-color-main);
    --ion-color-base-rgb: var(--ion-color-main-rgb);
    --ion-color-contrast: var(--ion-color-main-contrast);
    --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
    --ion-color-shade: var(--ion-color-main-shade);
    --ion-color-tint: var(--ion-color-main-tint);
  }
  
  ion-datetime {
    --background: #A0ACAB;
    --background-rgb: 160, 172, 172;
  
    border-radius: 16px;
    box-shadow: rgba(var(--ion-color-main-rgb), 0.3) 0px 10px 15px -3px;
  }

.header-ios ion-toolbar:last-of-type {
    --border-width: 0 0 0;
}

.m-auto {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
}

.mt-1 {
    margin-top: 1rem;
}

ion-avatar {
    max-width: 70px;
    max-height: 70px;
}

.page-w-footer {
    margin-bottom: 15vh;
}

ion-popover {
    --width: 150px;
}

.popover-no-overflow {
    --overflow: hidden;
}

.label-divisor-style {
    background-color: var(--ion-color-light-tint);
    height: 0px;
    border: 1.04323px solid rgba(230, 230, 232, 0.7);
    margin-bottom: 1vh;
    width: 93%;
}

ion-item {
    
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--ion-color-text-white);
}

.text-centered {
    text-align: center;
}

.toast-style {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    //transform: translateY(-10vh);
}

ion-toast.custom-toast-bold-text::part(message) {
    font-weight: bolder;
    font-size: x-large;
}

@media (min-width: 600px) {
    .m-auto {
        width: 60%;
        margin-right: auto;
        margin-left: auto;
    }  
}

@media (min-width: 1100px) {
    .m-auto {
        width: 30%;
    }  
}

.refresher-ios .refresher-pulling-icon, .refresher-ios .refresher-refreshing-icon,
.refresher-ios .refresher-pulling-text, .refresher-ios .refresher-refreshing-text {
    color: #ababab;
}


/* MyModal Styles */
.my-modal {
    top: 0;
    display: none;
    place-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.4);

    & footer {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.my-modal-container {
    // position: relative;
    // width: 80vw;
    max-width: 400px;
    // max-height: 100vh;
    // padding: 20px 28px;
    border-radius: 20px;
    background-color: var(--ion-color-gray);

    & .space-in-bottom {
        position: absolute;
        width: inherit;
        height: 60px;
        background-color: var(--ion-color-gray);
        position: fixed;
        left: 10%;
        bottom: 0px;
    }

    & .my-modal-body {
        max-height: 75vh;
        overflow-y: auto;
    }

    & .padding-special {
        padding: 10px 25px;
        & image-cropper {
            max-height: 50vh;
        }
    }
}

.my-modal-header {
    @include flex-display(flex-end, flex-start);
}

.my-modal-subtitle {
    margin: 32px auto;
    font-weight: 600;
}

.show-my-modal {
    display: grid;
}

.cropped-image-preview {
    object-fit: cover;
}

.show {
    display: grid;
}

.provisional-height {
    height: auto;
}

.delete-icon {
    position: absolute;
    top: -20px;
    left: 72px;
    zoom: .8;
    z-index: 999;
    color: black;
    font-size: 20px;
}


.my-modal-header {
    width: 100%;
}

.images-to-crop {
    margin: 5px;
    max-width: 100%;
    padding: 0px 6px;
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 8px;
    overflow-x: scroll;
}

.image-to-crop {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.current-image {
   border: 2px solid var(--ion-main-color);
   
}

.bullet-active-qvery {
    background: var(--ion-color-primary) !important;
    opacity: 1 !important;
}




.vjs-modal-dialog-content {
    display: none;
}

.vjs-error-display::before {
    display: none;
}

.video-js .vjs-tech {
    position: fixed !important;
    object-fit: fill;
}


.container-creator {
    position: fixed;
    bottom: 0px;
    height: max-content;
    width: 100%;
}

.chat-container-creator {
    max-height: 40vh !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0.5%, rgba(0, 0, 0, 0.5) 76.37%, rgba(0, 0, 0, 0) 100%);
    padding-bottom: 3rem !important;
}

.chat-container {
    overflow-y: auto;
    max-height: 30vh;
    height: max-content;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    width: 100%;
    margin-top: 20px;
    padding-top: 1rem;

    .chat-message {
        height: max-content;
        width: 100%;
        padding: 10px 20px;
        color: white;

        .user-name {
            margin-top: 0px;
            font-size: 1.1rem;
            color: #F2F2F2;
            opacity: 0.8;
            margin-bottom: 5px;
        }

        .user-message {
            margin-top: 0px;
            font-size: 1.3rem;
            color: var(--ion-color-text-white);
            margin-bottom: 0px;
        }
    }
}

#message_input {
    textarea {
        padding: 0px !important;
    }
}

// ion-action-sheet.btn-action-sheet {
//     --background: #f58840;
//     --color: --var;
// }
.my-custom-class .action-sheet-group {
    align-items: start;
    color: var(--ion-color-text-white);
    background: var(--ion-color-gray);
    --button-color:var(--ion-color-text-white);
    opacity: 1;
   & .action-sheet-cancel.sc-ion-action-sheet-ios::after {

    color: var(--ion-color-text-white) !important;
    background: var(--ion-color-gray) !important;
    opacity: 1 !important;
    
    }

    &.action-sheet-button {
        color: var(--ion-color-text-white) !important;
    }
    & .action-sheet-title {
        color: var(--ion-color-text-white) !important;
        font-weight: 600;
    }
}

ion-action-sheet.btn-action-sheet .btn {
    ion-icon {
        margin-right: 1rem !important;
        color: var(--ion-color-text-white);
    }
    align-items: start;
    color: var(--ion-color-text-white);
    background: var(--ion-color-gray);
}


@media print {
    html, body {
       display: none;  /* hide whole page */
    }
}

.alert-wrapper{
    background: var(--ion-color-gray)!important;
    & .alert-title, 
    & .alert-button,
    & .alert-radio-label{
        color: var(--ion-color-text-white)!important;
    }

}

ion-item {
    --color-hover: var(--ion-creator-image);
    --color-focused: var(--ion-main-color);
}

.native-input.sc-ion-input-ios[type="number"]::-webkit-inner-spin-button,
.native-input.sc-ion-input-ios[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ion-color-rose {
    --ion-color-base: #000;
    --ion-color-base-rgb: rgb(0, 0, 0);
    --ion-color-contrast: #fff;
    --ion-color-contrast-rgb: rgb(255, 255, 255);
    --ion-color-shade: #120901;
    --ion-color-tint: #117;
  }
